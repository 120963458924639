.company-page {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 50px;
  max-width: 1024px;
  margin: auto;

  .company-info,
  .map-container {
    background-color: #f5f5f5;
    padding: 25px;
    flex: 1 1 45%;
    border-radius: 20px;
    h2 {
      font-size: 18px;
      color: #333;
      margin-bottom: 15px;
    }

    .contact-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-top: 10px;

      .icon {
        color: #333;
        min-width: 20px; // Ensure icons align vertically
        margin-right: 10px;
      }

      .text {
        color: #666;
      }
    }

    // Style for the horizontal line
    .divider {
      border-bottom: 1px solid #ddd;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .contact-details {
      color: #666;
      line-height: 1.6;
    }

    .map {
      // width: 100%;
      height: 400px;
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  @media (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      flex-direction: column;
  }
}
