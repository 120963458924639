.TitleImage {
  .image-container {
    background-color: #007db6;
    position: relative;
    height: 180px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
    }
    .title-overlay {
      color: white;
      padding: 40px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 65px;
      animation: answer-up-down 0.5s ease-in-out;
      font-family: "Gasoek One";
    }
  }
}

@keyframes answer-up-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3.5px);
  }
}