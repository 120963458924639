.customers-table {
  width: 100%;
  border-collapse: collapse; 

  td {
    border: 0px solid #ccc;
    padding: 12px;
    text-align: center;

    img {
      height: auto;
      max-width: 200px;
    }
  }
}

@media (max-width: 600px) {
  .customers-table {
    td {
      img {
        padding: 0px !important;
      }
    }
  }
}
