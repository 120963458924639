.youth {
  background-color: #f5f5f5;
  padding: 25px;
  border-radius: 20px;
  width: 100%;
  h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }
  p {
    text-align : justify;

    // &:after {
    //   content: "";
    //   display: inline-block;
    //   width: 100%;
    // }
  }
}
