.product__section {
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
  max-width: 1024px;
  margin: auto;

  .product__info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #f5f5f5;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 20px;
    align-items: center;

    .product__category {
      display: flex;
      margin-bottom: 20px;

      span {
        display: inline-block;
        background-color: #333333; // 예시 색상
        color: white;
        padding-top: 7px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: 15px;
        border-radius: 10px;
        vertical-align: middle;
      }
      .product__description {
        p {
          text-align: center;
        }
      }
    }

    .product__bottom {
      display: flex;
      justify-content: space-between;
      gap: 25px;
      align-items: center;

      .product__details {
        flex: 1;
        text-align: justify;
      }
      .product__image {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 200px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .product__bottom {
    flex-direction: column-reverse; // 순서 반전

    .product__details {
      // 필요한 경우 추가 스타일링
    }
    .product__image {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 600px) {
  .product__section {
    gap: 20px;
    padding: 20px;
  }
}

@media (max-width: 455px) {
  .product__section .product__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .product__section .product__info .product__category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span{
      margin-bottom: 10px;
    }
  }
}
