.info__component {
  background-color: #f5f5f5;
  padding: 25px;
  border-radius: 20px;
  width: 100%;
  h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }

  .info__component__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;

    > div {
      flex: 1; // 각 div가 사용 가능한 공간의 동일한 비율을 차지하도록 설정
      display: flex;

      &:first-child,
      &:last-child {
        flex-basis: 50%; // 첫 번째와 마지막 div 전체의 50%
      }
    }

    .info__component__image {
      max-width: 100%;
      height: auto;
      object-fit: contain; // 이미지 비율을 유지하면서 전체 내용이 보이도록 조정
    }

    .info__component__explain {
      text-align: justify;
    }
  }
}
@media (max-width: 768px) {
  .info__component {
    .info__component__content {
      flex-direction: column;

      > div {
        &:first-child,
        &:last-child {
          flex-basis: auto; // 반응형에서는 각 div의 기본 크기 조정 해제
        }
      }
    }
  }
}
