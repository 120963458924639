.service-page {
  // display: flex;
  // flex-direction: column;

  .service-page__options {
    display: flex;
    justify-content: center;
    gap: 3px;
    padding: 20px;
    flex-wrap: wrap;
    
    .option {
      cursor: pointer;
      transition: background-color 1s, color 0.3s;
      padding: 10px;
      border-radius: 10px;
      font-weight: bold;
      &:hover {
        background-color: #9bcbff;
      }

      &.selected {
        background-color: #333;
        color: white;
      }
    }
  }
  .service-page__cont {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    .service-page__group {
      background-color: #f5f5f5;
      padding: 25px;
      border-radius: 20px;
      h2 {
        font-size: 18px;
        color: #333;
        margin-bottom: 15px;
      }
    }
  }
}
