.about-page {
  display: flex;
  flex-direction: column;

  .about-page__cont {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 50px;
    max-width: 1024px;
    margin: auto;

    .about-page__group {
      background-color: #f5f5f5;
      padding: 25px;
      border-radius: 20px;

      h2 {
        font-size: 18px;
        color: #333;
        margin-bottom: 15px;
      }
    }
    .about {
      flex: 1;
      .hashtag {
        font-size: 15px;
        // padding: 0px;
        margin-bottom: 5px;
        &__item {
          display: inline-block;
          background-color: #b9b7b9;
          color: white;
          border-radius: 20px;
          padding: 7px 9px;
          margin: 2px;
        }
      }
      p {
        // font-family: "Gowun Dodum";
        padding-top: 10px;
        span {
          position: relative;
          font-weight: bold;
          color: #2f9add;
          text-decoration: none;
        }
      }
    }
    .years {
      flex: 1;
      ul {
        padding: 25px;
        // font-family: "Gowun Dodum";
        li {
          padding: 5px;
          position: relative;
          // font-weight: bold;
          text-decoration: none;
        }
      }
    }
    .customer {
      h2 {
        font-size: 16px;
        // text-align: center;
      }
    }
  }
}
@media (max-width: 600px) {
  .about-page {
    .about-page__cont {
      gap: 20px;
      padding: 20px;
      flex-direction: column;
    }
  }
}
