.quality-page {
  display: flex;
  flex-direction: column;

  .quality-page__cont {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 50px;
    max-width: 1024px;
    margin: auto;

    .quality-page__group {
      background-color: #f5f5f5;
      padding: 25px;
      border-radius: 20px;
      width: 100%;
      h2 {
        font-size: 18px;
        color: #333;
        margin-bottom: 15px;
      }

      .color {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        img {
          max-width: 130px;
          height: auto;
          flex: 1 1 auto;
        }
      }
      .close {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        img {
          max-width: 300px;
        }
      }
      .quality {
        width: 100%;

        .hashtag {
          font-size: 15px;
          // padding: 0px;
          margin-bottom: 5px;
          &__item {
            display: inline-block;
            background-color: #b9b7b9;
            color: white;
            border-radius: 20px;
            padding: 7px 9px;
            margin: 2px;
          }
        }
        .picture__info {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          gap: 20px;
          padding-top: 20px;

          > div {
            flex: 1;
            display: flex;
            justify-content: center;
            // align-items: center;
            align-items: flex-end;
            &:first-child,
            &:last-child {
              flex-basis: 50%;
            }
          }
          .picture__info__explain {
            text-align: justify;
          }
        }
      }
      .assurance {
        width: 100%;
      }
      .health {
        width: 100%;
      }
      .coat {
        display: flex;
        flex-direction: raw;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        img {
          width: auto;
          height: 260px;
        }
        p {
          flex: 1;
          text-align: justify;
        }
      }
      .silk {
        display: flex;
        flex-direction: raw;
        gap: 20px;
        img {
          width: 50%;
        }
        p {
          text-align: justify;
        }
      }
    }

    @media (max-width: 600px) {
      gap: 20px;
      padding: 20px;
      flex-direction: column;
      .coat,
      .silk {
        flex-direction: column;
        img {
          width: 100% !important;
        }
      }
      .quality {
        .picture__info {
          flex-direction: column;
          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}
