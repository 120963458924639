.main-page {
  padding: 0;
  margin: 0;

  &__content {
    font-size: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 850px;
  }

  &__video {
    position: absolute;
    display: block;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .main-page__content {
    min-height: 550px; /* 모바일 환경에서의 최소 높이 */
  }
}
