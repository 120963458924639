@import "variables";
@import "mixins";
@import "reset";

// import fonts
/* Gasoek */
@font-face {
  font-family: "Gasoek One";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/GasoekOne-Regular.ttf") format("truetype");
}

/* GothicA1 */
@font-face {
  font-family: "Gothic A1";
  font-weight: 400;
  src: url("../fonts/GothicA1-Regular.ttf") format("truetype");
}

/* Gowun Dodum */
@font-face {
  font-family: "Gowun Dodum";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/GowunDodum-Regular.ttf") format("truetype");
}

/* NotoSansKR */
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 400;
  src: url("../fonts/NotoSansKR-Regular.ttf") format("truetype");
}

body {
  font-family: "Gothic A1", Arial, sans-serif;
  line-height: 1.6;
  color: $color-black;
  background-color: $color-white;
}

// 링크 스타일
a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// 반응형 레이아웃 설정
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;

  @include respond-mobile {
    padding: 0 10px;
  }
}
