.notfound-page {
  .notfound__body {
    // display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;

    img {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      border-radius: 20px;
      max-width: 500px;
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .notfound__content {
      margin-left: auto;
      margin-right: auto;
      background-color: #f5f5f5;
      padding: 25px;
      flex: 1 1 45%;
      border-radius: 20px;
      max-width: 500px;
      margin-bottom: 15px;
      .notfound__link {
        position: relative;
        font-weight: bold;
        color: #2f9add;
        text-decoration: none;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px; // 밑줄 두께
          bottom: -3px; // 밑줄 위치
          left: 0;
          background-color: #babfd9; // 밑줄 색상 
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .notfound-page {
    .notfound__body {
      padding: 0px;
      img {
        max-width: 300px;
      }
      .notfound__content {
        max-width: 300px;
      }
    }
  }
}
