// CSS 리셋
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
};

// HTML 요소의 기본 스타일 제거
body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, figure, figcaption, blockquote, dl, dd {
    margin: 0;
    padding: 0;
};

// 목록 스타일 초기화
ul[role='list'],
ol[role='list'] {
    list-style: none;
};

// A 태그 초기화
a {
    color: inherit;
    text-decoration: none;
};

// 이미지와 비디오의 크기 조정 방지
img,
video {
    max-width: 100%;
    height: auto;
};

// 테이블 스타일 초기화
table {
    border-collapse: collapse;
    border-spacing: 0;
};

// 입력 필드 스타일 초기화
input,
button,
textarea,
select {
    font: inherit;
    color: inherit;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    outline: inherit;
};
