.sidebar {
  .sidebar-right {
    width: 50%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background-color: white;
    color: black;
    z-index: 1000;
    animation: fallIn 0.7s ease forwards;

    .sidebar-content {
      padding: 10px;
      margin-top: 50px;
      nav div {
        padding: 10px;
        font-size: 22px;
        transition: transform 0.7s ease;
        cursor: pointer;
        a {
          text-decoration: none;
        }
        &:hover {
          color: #2f9add;
          transform: translateX(10px);
        }
      }
    }
    .close--btn {
      position: absolute;
      top: 15px;
      right: 20px;
      background: none;
      border: none;
      font-size: 35px;
      cursor: pointer;
    }
  }
  .sidebar-left {
    width: 50%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #1e608a;
    z-index: 1000;
    animation: slideIn 0.7s ease forwards;
  }
}

.sidebar.close {
  .sidebar-right {
    animation: fallOut 0.5s ease forwards;
  }
  .sidebar-left {
    animation: slideOut 0.7s ease forwards;
  }
}

.sidebar.hide{
  display: none;
}

@keyframes slideIn {
  0% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 0.9;
  }
}

@keyframes slideOut {
  0% {
    width: 50%;
    opacity: 0.9;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}

@keyframes fallIn {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

@keyframes fallOut {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
