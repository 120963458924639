.footer__wrap {
  background-color: #222222;
  padding: 50px 20px 40px;

  .footer {
    color: #7a7a7a;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__info {
      font-size: 0.75em; // 작은 텍스트 사이즈
      line-height: 1.5; // 줄 간격 설정

      div {
        margin-bottom: 5px; // 각 줄 사이의 간격
      }
    }

    .copyright {
      padding-top: 10px;
      color: #a8a8a8;
      font-family: "Gowun Dodum";
      font-size: 16px;
    }

    .footer__links {
      padding-left: 15px;
      display: flex;
      align-items: center;
      
      img {
        max-width: 150px; // 로고 크기 조정
        margin-right: 20px;
        // max-height: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;

      .footer__links {
        margin-top: 20px;
        padding-left: 0;
        img {
          margin-right: 20px;
          margin-bottom: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .footer {
    width: 1200px;
    margin: 0 auto;
  }
}
