.color__component {
  background-color: #f5f5f5;
  padding: 25px;
  border-radius: 20px;
  width: 100%;

  h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }

  .color__option {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 1x4 grid */
    gap: 10px;
    p {
      padding-top: 10px;
      font-weight: bold;
    }
  }
}

/* 2x2 grid */
@media (max-width: 768px) {
  .color__component {
    .color__option {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* 4x1 grid */
@media (max-width: 480px) {
  .color__component {
    .color__option {
      grid-template-columns: 1fr;
    }
  }
}
