.customers-table {
  width: 100%; // 테이블 전체 너비
  border-collapse: collapse; // 셀 간격 제거

  td {
    border: 0px solid #ccc;
    padding: 12px;
    text-align: center;
    
    img {
      width: 100%;
      height: auto;
      max-width: 350px;
    }
  }
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.9);

  .modal-content {
    margin: auto;
    display: block;
    padding-top: 70px;
    width: 90%;
    max-width: 700px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    animation-name: zoom;
    animation-duration: 0.6s;
    cursor: zoom-out;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  .caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media (max-width: 600px) {

}
