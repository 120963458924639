@import "../variables";

.head {
  position: relative;

  .header {
    font-family: "Noto Sans KR";
    font-weight: 600;
    color: #3b3b3b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 25px;
    background-color: $color-white;
    height: 70px;

    &__logo {
      margin-right: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 30px;
        cursor: pointer;
      }
    }

    &__nav {
      flex-grow: 1;
      height: 100%;
      .nav-group {
        display: flex;
        align-items: center;
        // justify-content: center;
        list-style: none;
        gap: 40px;
        font-size: 18px;
        height: 100%;

        div {
          cursor: pointer;
          text-decoration: none;
          position: relative; // 가상 요소 위치 기준 설정
          transition: color 0.7s;
          z-index: 2;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 0;
            background-color: #eaeff8;
            height: 2px;
            bottom: 2px;
            transition: width 0.3s; // 너비 변화 효과
            z-index: -1;
          }

          &:hover {
            color: #2f9add;

            &::before {
              width: 100%;
            }
          }
        }
      }
    }
    .header--sidebar {
      font-size: 20px;
      cursor: pointer;
    }
  }

  @media (min-width: 1200px) {
    .header {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media (max-width: 1000px) {
    .header {
      &__logo {
        margin-right: 10%;
      }
    }
  }
  @media (max-width: 700px) {
    .header {
      &__nav {
        display: none;
      }
    }
  }
}
