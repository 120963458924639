.faq-list {
  background-color: #f5f5f5;
  padding: 25px;
  border-radius: 20px;
  width: 100%;
  h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }

  .faq-item {
    padding-bottom: 20px;
    text-align : justify;
  }
}
